import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import REGX from '../constants/Regx';
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import * as Loader from "react-loader-spinner";

export default function CtaFloatForm(props) {
    const navigate = useNavigate();
    const captchaRef = useRef(null)
    const [ISLoading, setISLoading] = useState(false);
    const [GetAQuoteForm, setGetAQuoteForm] = useState({
        processing: false,
        fullName: "",
        fullNameError: "",
        email: "",
        emailError: "",
        mobile: "",
        mobileError: "",
        services: "-1",
        servicesError: "",
        message: "",
        messageError: ""
    });

    useEffect(() => {
        setGetAQuoteForm({
            processing: false,
            fullName: "",
            fullNameError: "",
            email: "",
            emailError: "",
            mobile: "",
            mobileError: "",
            services: "-1",
            servicesError: "",
            message: "",
            messageError: ""
        });
    }, [props.isCtaVisible]);

    const submitHandler = (e) => {
        e.preventDefault();
        // const token = captchaRef.current.getValue();
        // captchaRef.current.reset();
        let errorFound = false;
        setISLoading(true);
        setGetAQuoteForm({
            ...GetAQuoteForm,
            processing: true
        });

        let GetAQuoteFormField = { ...GetAQuoteForm };

        if (GetAQuoteFormField.fullName === "") {
            errorFound = true;
            GetAQuoteFormField.fullNameError = "this field is required";
        } else if (!REGX.FULLNAME.test(GetAQuoteFormField.fullName)) {
            errorFound = true;
            GetAQuoteFormField.fullNameError = "alphabatic only";
        } else {
            GetAQuoteFormField.fullNameError = "";
        }
        if (GetAQuoteFormField.email === "") {
            errorFound = true;
            GetAQuoteFormField.emailError = "this field is required";
        } else if (!REGX.EMAIL.test(GetAQuoteFormField.email)) {
            errorFound = true;
            GetAQuoteFormField.emailError = "enter valid email";
        } else {
            GetAQuoteFormField.emailError = "";
        }
        if (GetAQuoteFormField.mobile === "") {
            errorFound = true;
            GetAQuoteFormField.mobileError = "this field is required";
        } else if (!REGX.MOBILE_NUMBER.test(GetAQuoteFormField.mobile)) {
            errorFound = true;
            GetAQuoteFormField.mobileError = "enter valid phone number";
        } else {
            GetAQuoteFormField.mobileError = "";
        }
        if (GetAQuoteFormField.services === "-1") {
            errorFound = true;
            GetAQuoteFormField.servicesError = "this field is required";
        } else {
            GetAQuoteFormField.servicesError = "";
        }

        if (GetAQuoteFormField.message === "") {
            errorFound = true;
            GetAQuoteFormField.messageError = "this field is required";
        } else {
            GetAQuoteFormField.messageError = "";
        }

        if (errorFound) {
            setGetAQuoteForm({
                ...GetAQuoteFormField,
                processing: false
            });
            setISLoading(false);
        } else {
            let formData = new FormData();
            formData.append("fullName", GetAQuoteFormField.fullName);
            formData.append("email", GetAQuoteFormField.email);
            formData.append("mobile", GetAQuoteFormField.mobile);
            formData.append("service", GetAQuoteFormField.services);
            formData.append("message", GetAQuoteFormField.message);

            axios({
                url: `https://www.anika.in/mails/get_quote.php`,
                method: "post",
                responseType: 'json',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log("form"+response.data);
                if (response.data.status === "success") {
                    setGetAQuoteForm({
                        processing: false,
                        fullName: "",
                        fullNameError: "",
                        email: "",
                        emailError: "",
                        mobile: "",
                        mobileError: "",
                        services: "-1",
                        servicesError: "",
                        message: "",
                        messageError: ""
                    });
                    props.handleCancel();
                    navigate("/thank-you");
                    setISLoading(false);
                } else {
                    alert(response.data.message);
                    setISLoading(false);
                }
                // alert("success");
            }, error => {
                console.log("error", error);
                alert("error");
                setISLoading(false);
            });
        }
    }
    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };
    return (
      <>
     <div className={props.isCtaVisible ? "cta-float active":"cta-float"} open={props.isCtaVisible}>
        <button type="button" className="close" onClick={() => props.handleOk()}>
             <span aria-hidden="true">&times;</span>
           </button>
        <h4>Get a Free <span className="highlight">Consultation</span></h4>
        <form onSubmit={(e) => submitHandler(e)}>
            <input type="hidden" className="form-control" name="enquery-form" id="enquery-form3" value="enquery-form" placeholder="Enquery Form" />
                <div className="form-group input-box">
                        <input
                          type="text"
                          name="fullName"
                          className="form-input"
                          placeholder="Full Name*"
                          value={GetAQuoteForm.fullName}
                          onChange={(e) =>
                            setGetAQuoteForm({
                              ...GetAQuoteForm,
                              fullName: e.target.value,
                              fullNameError: "",
                            })
                          }
                          disabled={GetAQuoteForm.processing}
                        />
                        <span className="error">
                          {GetAQuoteForm.fullNameError}
                        </span>
                </div>
                <div className="form-group input-box">
                        <input
                          type="email"
                          name="email"
                          className="form-input"
                          placeholder="Email Address*"
                          value={GetAQuoteForm.email}
                          onChange={(e) =>
                            setGetAQuoteForm({
                              ...GetAQuoteForm,
                              email: e.target.value,
                              emailError: "",
                            })
                          }
                          disabled={GetAQuoteForm.processing}
                        />
                        <span className="error">{GetAQuoteForm.emailError}</span>
                      </div>
                      <div className="form-group input-box">
                      <PhoneInput
                         defaultCountry="IN"
                         international 
                        enableSearch={true}
                        className="form-input"
                        placeholder="Phone Number*"
                          value={GetAQuoteForm.mobile}
                          onChange={(e) =>
                            // console.log("Phone Number",e)
                            setGetAQuoteForm({
                              ...GetAQuoteForm,
                              mobile: e,
                              mobileError: "",
                            })
                          }
                          disabled={GetAQuoteForm.processing}
                      />
                      
                        <span className="error">{GetAQuoteForm.mobileError}</span>
                      </div>
                      <div className="form-group input-box">
                        <select
                          name="service"
                          className="form-input"
                          value={GetAQuoteForm.services}
                          onChange={(e) =>
                            setGetAQuoteForm({
                              ...GetAQuoteForm,
                              services: e.target.value,
                              servicesError: "",
                            })
                          }
                          disabled={GetAQuoteForm.processing}
                        >
                          <option value="">Select Services</option>
                          <option value="Fertilisers">Fertilisers</option>
                          <option value="Paper">Paper</option>
                          <option value="Pharma">Pharma</option>
                          <option value="Real Estate">Real Estate</option>
                          <option value="Chemicals">Chemicals</option>
                          <option value="Timber">Timber</option>
                          <option value="Hospitality">Hospitality</option>
                          <option value="Surface Protection Films">Surface Protection Films</option>
                          <option value="Label Films">Label Films</option>
                          <option value="Others">Others</option>
                        </select>
                        <span className="error">
                          {GetAQuoteForm.servicesError}
                        </span>
                      </div> 
                      <div className="form-group input-box">
                        <textarea
                          className="form-input"
                          name="message"
                          placeholder="Message"
                          value={GetAQuoteForm.message}
                          onChange={(e) =>
                            setGetAQuoteForm({
                              ...GetAQuoteForm,
                              message: e.target.value,
                              // messageError: "",
                            })
                          }
                          disabled={GetAQuoteForm.processing}
                        ></textarea>
                        {/* <span className="error">
                          {contactForm.messageError}
                        </span> */}
                      </div>
                     <div className="form-group input-box">
                     {ISLoading == true ? (
                    <p className='proccessing-sec'>
                      <Loader.Oval visible={true} color="#7d2c29" height={40} width={40}  ariaLabel="oval-loading" />
                      &nbsp; Please wait. We are proccessing your request..{" "}
                    </p>
                  ) : (
                    <div className="col-md-6 offset-md-3 text-center">
                    <button type="submit" value="" className="btn custom-btn-outline" id="your_submit2">Submit</button>
                    </div>
                  )}
                          
           </div>
        </form>
    </div>
      </>
    );
}