import React,{useState} from 'react';
import CtaFloatForm from './CtaFloat';

export default function GetAQuoteButton(props) {
    const [isCtaVisible, setIsCtaVisible] = useState(false);
    const showCta = () => {
      setIsCtaVisible(true);
    };
  
    const handleOk = () => {
      setIsCtaVisible(false);
    };
  
    const handleCancel = () => {
      setIsCtaVisible(false);
    };
    return (
        <>
       <a onClick={props.CtaFloat? showCta : ""} className={props.btn ? props.btn:"btn-1"}>{props.Title}</a>
       {/* {
        isCtaVisible === true && ( */}
          <CtaFloatForm isCtaVisible={isCtaVisible} handleOk={handleOk} handleCancel={handleCancel} />
        {/* )
       } */}
     
        </>
    );
}
