import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
  FaEnvelope,
  FaAngleUp,
  FaPhoneAlt,
  FaYoutube,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { SOCIAL_LINK } from "../constants/Constants";
import GetAQuoteButton from "./GetAQuoteButton";

function Footer(props) {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const fetchUrl = (page_section) => {
    navigate(`/about/#${page_section}`);
  };
  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        window.scrollTo({
          top: document.querySelector(`${location.hash}`).offsetTop,
          behavior: "smooth",
        });
      }, 500);
    }
  }, [location.hash]);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);

  const { pathname } = useLocation();
  useEffect(() => {
    // console.log("pathname",pathname);
    window.scrollTo(0, 0);
    var element = document.getElementById("headerPanel");
    element.scrollIntoView();
    return props.children;
  }, [pathname]);

  return (
    <>
      <footer>
        <div className="top-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="footer-logo">
                  <Link to="/">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                      alt="header-logo"
                      className="img-fluid"
                    />
                  </Link>
                </div>
                <div className="footer-con">
                  <h5>Managing Businesses Through Relationships</h5>
                </div>
                <div className="follow-us">
                  <ul>
                    <li>
                      <a href={SOCIAL_LINK.FACEBOOK} target="_blank">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href={SOCIAL_LINK.YOUTUBE} target="_blank">
                        <FaYoutube />
                      </a>
                    </li>
                    <li>
                      <a href={SOCIAL_LINK.INSTAGRAM} target="_blank">
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="social-media">
                  <ul className="pl-0">
                    <li>
                      <span className="icon-width">
                        <FaEnvelope />
                      </span>{" "}
                      <a href="mailto:info@anika.in">info@anika.in</a>
                    </li>
                    <li>
                      <span className="icon-width">
                        <FaPhoneAlt />
                      </span>{" "}
                      <a href="tel:+91-11-4728 9900">+91-11-4728 9900</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-2">
                <h5>Useful Links</h5>
                <div className="quick-links">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      {" "}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          fetchUrl("vision");
                        }}
                      >
                        Vision
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          fetchUrl("vision");
                        }}
                      >
                        Mission
                      </a>
                    </li>
                    <li>
                      <Link to="/leadership">Leadership</Link>
                    </li>
                    <li>
                      {" "}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          fetchUrl("our-approch");
                        }}
                      >
                        Our Approach
                      </a>
                    </li>

                    <li>
                      <Link to="/our-story">Our Story</Link>
                    </li>
                    <li>
                      <Link to="/ventures">Ventures</Link>
                    </li>
                    {/* <li>
                      <Link to="/blogs">Blogs</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* <div className="col-md-1"></div> */}
              <div className="col-md-3 pl-4">
                <h5>Businesses</h5>
                <div className="quick-links">
                  <ul>
                    <li>
                      <Link to="/paper">Paper</Link>
                    </li>
                    <li>
                      <Link to="/pharmaceuticals">Pharma</Link>
                    </li>
                    <li>
                      <Link to="/fertilisers">Fertilizer</Link>
                    </li>
                    <li>
                      <Link to="/surface-protection-film">Surface Protection Film</Link>
                    </li>
                    <li>
                      <Link to="/biodiesel">Biodiesel</Link>
                    </li>
                    <li>
                      <Link to="/timber">Timber</Link>
                    </li>
                    <li>
                      <Link to="/waste-paper-management">Waste Paper Management</Link>
                    </li>
                    <li>
                      <Link to="/nbfc">NBFC</Link>
                    </li>
                    <li>
                      <Link to="/label-films">Label Films</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <h5>Contact Information</h5>
                <div className="reach-us">
                  <ul>
                    <li>
                      <span className="icon-width">
                        <img
                          className="img-fluid"
                          src={`${process.env.PUBLIC_URL}/assets/images/india-flag.jpg`}
                          alt="India Flag"
                        />
                      </span>
                      <a
                        href="https://goo.gl/maps/Cv7voWkG21SppGRY7"
                        target="_blank"
                      >
                        <h6>Anika International Pvt. Limited</h6>
                      </a>
                    </li>
                    <li>
                      <span className="icon-width">
                        <FaMapMarkerAlt />
                      </span>
                      <a
                        href="https://goo.gl/maps/Cv7voWkG21SppGRY7"
                        target="_blank"
                      >
                        F-402, 4th Floor, Ras Vilas Mall, Plot No. D-1, District
                        Center, Saket New Delhi – 110 017, INDIA
                      </a>
                    </li>
                    <li>
                      <span className="icon-width">
                        <img
                          className="img-fluid"
                          src={`${process.env.PUBLIC_URL}/assets/images/uae-flag.jpg`}
                          alt="India Flag"
                        />
                      </span>
                      <a
                        href="https://maps.app.goo.gl/6E7VrwJP7yqjFRUv7"
                        target="_blank"
                      >
                        <h6>Anika FZE</h6>
                      </a>
                    </li>
                    <li>
                      <span className="icon-width">
                        <FaMapMarkerAlt />
                      </span>
                      <a
                        href="https://maps.app.goo.gl/6E7VrwJP7yqjFRUv7"
                        target="_blank"
                      >
                        {" "}
                        LOB 15, Office 15308 Jebel Ali Free Zone P.O. Box
                        261482, Dubai, UAE
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="container">
            <p>
              © Anika International Pvt. Ltd. {(new Date().getFullYear())}. ALL RIGHTS RESERVED. Design
              and Developed by{" "}
              <a href="https://www.newvisiondigital.co/" target="_blank">
                <img
                  src={`${process.env.PUBLIC_URL}/nvd-logo.png`}
                  className="img-fluid"
                  alt="nvd logo"
                />
              </a>
            </p>
          </div>
        </div>
      </footer>
      <div className="book-now">
       <GetAQuoteButton Title="Request A Quote" CtaFloat={true} />
      </div>
      <a
        id="back-to-top"
        className="btn btn-light btn-lg back-to-top"
        role="button"
        style={{ display: visible ? "flex" : "none" }}
        onClick={scrollToTop}
      >
        <FaAngleUp />
      </a>
      {/* <div className="whr">
        <div className="amppulse"></div>
        <div className="amppulse"></div>
        <div className="amppulse"></div>

        <a
          href={`https://wa.me/8394038521`}
          data-action="share/whatsapp/share"
          target="_blank"
          className="whatsapp-info amppulse"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className="whatsapp-icon" color="#fff" />
        </a>
      </div> */}
    </>
  );
}

export default Footer;
